<template>
    <div>
        <ul class="c-label-listing" v-if="items.length">
            <li v-for="(item, index) in items" :key="index" class="d-flex align-items-center" :class="{'c-label-listing__item--active': item.label === activeLabel }">
                <div class="flex-grow-1">
                    <span class="c-label-listing__icon"><i class="fas fa-chevron-left"></i></span>
                    <span @click="editLabel(item)">{{ item.label }}</span>
                </div>
                <mercur-button v-if="item.label === activeLabel" class="btn btn-icon btn-icon-mini" :disabled="isAction" @click.native="triggerDelete(item)"><i class="fas fa-trash"></i></mercur-button>
                <mercur-button v-if="item.label === activeLabel" class="btn btn-icon btn-icon-mini" :disabled="isAction" @click.native="updateLabel(item)"><i class="fas fa-save"></i></mercur-button>
            </li>
        </ul>
        <mercur-dialog :is-open.sync="!!draft.item">
            <p>{{ draft.message }}</p>
            <template #footer>
                <mercur-button class="btn" @click.native="cancelAction">Cancel</mercur-button>
                <mercur-button class="btn btn-primary" @click.native="deleteLabel">Confirm</mercur-button>
            </template>
        </mercur-dialog>
    </div>
</template>

<script>

export default {
    name: 'LabelListing',
    props: {
        items: {
            required: true,
        },
    },
    data () {
        return {
            activeLabel: null,
            isAction: false,
            draft: {
                item: null,
                message: '',
            },
        }
    },
    methods: {
        editLabel (item) {
            this.activeLabel = item.label
            this.$emit('edit', item)
        },
        deleteLabel () {
            this.isAction = true
            this.$emit('delete', this.draft.item)
            this.cancelAction()
        },
        updateLabel (item) {
            this.isAction = true
            this.$emit('update', item)
        },
        triggerDelete (item) {
            this.draft = {
                item,
                message: `Are you sure you want to delete label ${item.label}`,
            }
        },
        cancelAction () {
            this.$set(this, 'draft', { item: null, message: '' })
        },
    },
}
</script>

<style lang="scss" scoped>
    .c-label-listing {
        list-style: none;
        padding-left: 0;
        li {
            padding: 0 5px;

            &:hover {
                font-weight: bold;
            }
        }

        &__icon {
            margin-right: 5px;
            font-size: 14px;
            vertical-align: center;
        }

        &__item--active {
            background-color: rgba(0,0,0,0.09);
            font-weight: bold;
            text-decoration: underline;
        }
    }
</style>
