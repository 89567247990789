<template>
    <div>
        <mercur-dialog :is-open.sync="isAdding">
            <form @submit.prevent="submitForm" class="form">
                <mercur-input v-model="form.label" @keyup="toLowerCase" :class="getValidationClass($v, 'label')">
                    Add label for product definition
                    <template #note>
                        <span class="form-error" v-if="!$v.form.label.required">required</span>
                    </template>
                </mercur-input>
                <div class="text-right">
                    <mercur-button @click.native="isAdding = false" :disabled="loading" class="btn">Cancel</mercur-button>
                    <mercur-button type="submit" :disabled="loading" class="btn btn-primary">Submit</mercur-button>
                </div>
            </form>
        </mercur-dialog>
        <mercur-button @click.native="isAdding = true"  class="btn btn-icon btn-icon-mini btn-yellow text-white"><i class="fas fa-plus"></i></mercur-button>
    </div>
</template>

<script>
import FormMixin from '@/mixins/Form'
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'AddLabel',
    mixins: [ FormMixin ],
    props: {
        action: {
            type: String,
        },
        message: {
            type: String,
        },
    },
    data () {
        return {
            isAdding: false,
            form: {
                label: '',
            },
        }
    },
    validations: {
        form: {
            label: {
                required,
            },
        },
    },
    methods: {
        toLowerCase () {
            this.form.label = this.form.label.toLowerCase()
        },
        submitForm () {
            this.form.options = {}
            this.submit(this.action, this.form).then((data) => {
                this.$root.$emit('notification:global', {
                    message: this.message,
                })
                this.form = {
                    label: '',
                }
                this.$emit('success', data.data)
            }).finally(() => {
                this.isAdding = false
            })
        },
    },

}
</script>

<style lang="scss" scoped>
    .add-dialog {
        width: 400px;
    }
</style>
