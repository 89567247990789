<template>
    <div>
        <template v-if="shopAgreements && agreement">
            <pretty-select
                :get-option-label="agreement => `${agreement.agreementName || ''} (${agreement.applicationName} - ${agreement.countryCode})`"
                v-model="shopAgreement"
                @input="getProductDefinitions"
                :options="shopAgreements"
                :reduce="agreement => agreement.shopHash"
                :auto-select-first-option="true"
                placeholder="Select agreement"
                class="mb-3"
            />
        </template>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import PrettySelect from '@/components/utils/PrettySelect'

export default {
    name: 'ShopAgreements',
    components: { PrettySelect },
    data () {
        return {
            shopAgreement: null,
        }
    },
    computed: {
        ...mapState('products', ['shopAgreements', 'agreement']),
    },
    methods: {
        ...mapActions('products', ['fetchShopAgreements', 'setShopAgreement']),
        getProductDefinitions (value) {
            if (!value) {
                return
            }
            const agreement = this.shopAgreements.find((agreement) => {
                return agreement.shopHash === value
            })
            this.setShopAgreement(agreement)
            this.$emit('changed', agreement)
        },
        fetch () {
            this.$set(this, 'shopAgreement', null)
            this.setShopAgreement(null)
            this.fetchShopAgreements().then(() => {
                this.$set(this, 'shopAgreement', JSON.parse(JSON.stringify(this.agreement.shopHash)))
            })
        },
        listen () {
            this.fetch()
        },
    },
    created () {
        this.fetch()
    },
    mounted () {
        this.$bus.$on('shopWasChanged', this.listen)
    },
    destroyed () {
        this.$bus.$off('shopWasChanged', this.listen)
    },
}
</script>
